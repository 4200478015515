import axios from "axios";
import { useEffect } from "react";
import config from "../config";

export default function Success() {
  const getUserDat = async () => {
    await axios.get(`${config.apiBaseUrl}/auth/google`).then((res) => {
      console.log("res :");
    });
  };

  useEffect(() => {
    getUserDat();
  }, []);
  return (
    <div>
      <h2> Hello World</h2>
    </div>
  );
}
