// src/config.js
const ENV = process.env.REACT_APP_ENV || "dev"; // Default to 'dev' if not set

const config = {
  apiBaseUrl:
    ENV === "dev"
      ? process.env.REACT_APP_API_BASE_URL_DEV
      : process.env.REACT_APP_API_BASE_URL_PROD,

  stripePublicKey:
    ENV === "dev"
      ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_DEV
      : process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD,
};

export default config;
