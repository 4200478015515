import { useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";

import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";

import "katex/dist/katex.min.css";

function BotMessage({ text }) {
  const handleClick = (event) => {
    switch (event.detail) {
      case 1: {
        break;
      }
      case 2: {
        copyScript();
        break;
      }
      default: {
        break;
      }
    }
  };

  const [copied, setCopied] = useState(false);
  const copyScript = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const preprocessLaTeX = (content) => {
    // Replace block-level LaTeX delimiters \[ \] with $$ $$

    const blockProcessedContent = content.replace(
      /\\\[(.*?)\\\]/gs,
      (_, equation) => `$$${equation}$$`
    );
    // Replace inline LaTeX delimiters \( \) with $ $
    const inlineProcessedContent = blockProcessedContent.replace(
      /\\\((.*?)\\\)/gs,
      (_, equation) => `$${equation}$`
    );
    return inlineProcessedContent;
  };

  return (
    <div
      onClick={handleClick}
      className="flex cursor-pointer items-start my-4 last:mb-0 select-none"
    >
      <img
        className="rounded-full mr-4"
        src="/images/bevinzey-bot.png"
        width="40"
        height="40"
        alt="User 01"
      />
      <div className="relative flex items-center">
        <div>
          <pre className="text-sm font-inter whitespace-pre-wrap bg-white text-slate-800 p-3 rounded-lg rounded-tl-none border border-slate-200 shadow-md mb-1">
            <ReactMarkdown
              children={preprocessLaTeX(text)}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || "");
                  return !inline && match ? (
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, "")}
                      style={okaidia}
                      language={match[1]}
                      PreTag="div"
                      {...props}
                    />
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
              }}
              remarkPlugins={[remarkMath]}
              rehypePlugins={[rehypeKatex]}
            />
          </pre>
        </div>
        {copied && (
          <span className="text-[11px] absolute -right-[25px] text-white bg-[#8886fe] rounded p-1 mb-1">
            <MdOutlineContentCopy size={12} />
          </span>
        )}
      </div>
    </div>
  );
}

export default BotMessage;
