import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { clearMessage, setMessage } from "../redux/message";

import parse from "html-react-parser";
import config from "../config";

function BlogList() {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  //Get all blogs
  const formatBlogName = (name) => {
    return name.toLowerCase().replace(/ /g, "-");
  };

  const [visible, setVisible] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const getAllBlogs = async () => {
    setVisible(true);
    try {
      await axios
        .get(`${config.apiBaseUrl}/blog/findall`)
        .then((res) => {
          setBlogs(res?.data);
        })
        .catch((error) => {
          dispatch(
            setMessage(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString()
            )
          );
        });
    } catch (error) {
      dispatch(
        setMessage(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        )
      );
    }
    setVisible(false);
  };

  useEffect(() => {
    getAllBlogs();
  }, []);
  useEffect(() => {
    dispatch(clearMessage());
  }, []);

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/*  Page header */}
          <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
            <h1 className="h1" data-aos="fade-up">
              Refreshing news for students and educators
            </h1>
          </div>

          {/*  Featured article */}

          {/*  Articles list */}
          <div className=" md:mx-auto max-w-xs md:max-w-none">
            {/*  Section title */}
            {/* <h4 className="h4 pb-6 mb-10 border-b border-gray-700" data-aos="fade-up">Latest articles</h4> */}

            {/*  Articles container */}
            <div className="grid gap-6 md:gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-center md:items-start">
              {visible ? (
                <p>Loading...</p>
              ) : (
                <>
                  {blogs.map((blog, idx) => {
                    return (
                      <article
                        className="flex flex-col md:h-full "
                        key={idx}
                        data-aos="fade-up"
                      >
                        <header>
                          <Link
                            to={`/blog-details/${blog?.id}/${formatBlogName(
                              blog?.title
                            )}`}
                            className="block mb-6"
                          >
                            <figure className="relative w-full h-40 bg-red-600 md:h-0 md:pb-9/16 md:overflow-hidden rounded-sm">
                              <img
                                className="absolute  w-full h-full object-cover inset-0 transform hover:scale-105 transition duration-700 ease-out"
                                src={
                                  blog.BlogPicture[blog.BlogPicture.length - 1]
                                    ?.pictureUrl
                                }
                                alt="News 01"
                              />
                            </figure>
                          </Link>
                          <div className="mb-3">
                            <ul className="flex flex-wrap text-xs font-medium -m-1">
                              <li className="m-1">
                                <Link className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-orange-600 hover:bg-orange-700 transition duration-150 ease-in-out">
                                  AI
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <h3 className="md:h4 h5 w-80 md:w-full  md:max-w-none truncate mb-2">
                            <Link
                              to={`/blog-details/${blog?.id}/${formatBlogName(
                                blog?.title
                              )}`}
                              className="hover:text-gray-100 transition  duration-150 ease-in-out"
                            >
                              {blog?.title}
                            </Link>
                          </h3>
                        </header>
                        <div className="truncate w-80 md:w-full  max-h-6 md:max-h-12">
                          <p className="md:text-lg text-sm   text-gray-400 grow">
                            {blog?.content && parse(blog?.content)}
                          </p>
                        </div>
                      </article>
                    );
                  })}
                </>
              )}

              {/*  1st article */}
            </div>
          </div>

          {/*  Pagination */}
          {/* <nav className="flex justify-center pt-16" role="navigation" aria-label="Pagination Navigation">
            <ul className="inline-flex flex-wrap font-medium text-sm -m-1">
              <li className="m-1">
                <span className="inline-flex h-10 min-w-10 justify-center items-center bg-gray-800 px-4 rounded-full text-gray-500">Prev</span>
              </li>
              <li className="m-1">
                <Link to="#" className="inline-flex h-10 min-w-10 justify-center items-center bg-gray-800 px-2 rounded-full text-gray-300 hover:bg-orange-600 transition-colors duration-150 ease-in-out">1</Link>
              </li>
              <li className="m-1">
                <Link to="#" className="inline-flex h-10 min-w-10 justify-center items-center bg-gray-800 px-2 rounded-full text-gray-300 hover:bg-orange-600 transition-colors duration-150 ease-in-out">2</Link>
              </li>
              <li className="m-1">
                <Link to="#" className="inline-flex h-10 min-w-10 justify-center items-center bg-gray-800 px-2 rounded-full text-gray-300 hover:bg-orange-600 transition-colors duration-150 ease-in-out">3</Link>
              </li>
              <li className="m-1">
                <span className="inline-flex h-10 min-w-10 justify-center items-center bg-gray-800 px-2 rounded-full text-gray-500">...</span>
              </li>
              <li className="m-1">
                <Link to="#" className="inline-flex h-10 min-w-10 justify-center items-center bg-gray-800 px-2 rounded-full text-gray-300 hover:bg-orange-600 transition-colors duration-150 ease-in-out">12</Link>
              </li>
              <li className="m-1">
                <Link to="#" className="inline-flex h-10 min-w-10 justify-center items-center bg-gray-800 px-4 rounded-full text-gray-300 hover:bg-orange-600 transition-colors duration-150 ease-in-out">Next</Link>
              </li>
            </ul>
          </nav> */}
        </div>
      </div>
    </section>
  );
}

export default BlogList;
