import axios from "axios";
import "./FileUpload.css";
import config from "../../config";

const FileUpload = ({ files, text, getUserData, userId, setImageLoading }) => {
  const uploadHandler = (event) => {
    const file = event.target.files[0];
    setImageLoading(true);
    const formData = new FormData();
    formData.append("file", file, file?.name);
    axios
      .post(`${config.apiBaseUrl}/files/upload`, formData)
      .then((res1) => {
        axios
          .post(`${config.apiBaseUrl}/users/picture/update/` + userId, {
            urlPhoto: res1.data,
          })
          .then((res2) => {
            getUserData();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="file-card relative h-full flex cursor-pointer">
        <div className="file-inputs">
          <input
            accept={"image/*"}
            onClick={(event) => {
              event.target.value = null;
            }}
            type="file"
            onChange={uploadHandler}
          />
          <button className="cursor-pointer">{text}</button>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
